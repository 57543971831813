import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Table, message, notification, Icon, Tooltip, Spin } from "antd";

//Component import
import LocationPageBuilder from "../PageBuilder";
import Empty from "../../../components/Custom/Empty";

//Utility
import { sortFunction } from "../../../../utility/sorter";
import { COOKIE, getCookie } from "../../../../services/cookie";
import { fetchApi } from "../../../../services/api";
import { cloneDeep, filter } from "lodash";
import { formatDate } from "../../../../utility/date";
import moment from "moment";

class TallTree extends Component {
  componentRef = React.createRef();
  state = {
    addModal: false,
    data: [],
    dynamicTableData: [],
    blockNumber: null,
    blockNoList: [],
    block_uuid: null,
    researchCode_uuid: null,
    researchCodeList: [],
    researchCode: null,
    tallTree: [],
    tallTreeSelectedData: [],
    researchDate: null,
    editData: null,
    isEdit: false,
    isDelete: false,
    addPopulateData: null,
    tableLoader: false,
    tallResearchData: [],
    tallResearchUuid: null,
    pagination: true,
    isAdded: false
  };

  componentDidMount() {
    this.setState({ tableLoader: true });
    this.fetchTallTreeResearch();
    this.fetchBlockNo();
    const tableCon = ReactDOM.findDOMNode(this.refs["table"]),
      table = tableCon.querySelector("table"),
      header = table.createTHead(),
      row = header.insertRow(0);
    //cell = row.insertCell(0);
    row.innerHTML = `<h1>Research Tall Tree - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
    : ${getCookie(COOKIE.FARM)}${" "} </h1>`;
    table.setAttribute("id", "table-to-xls");
    table.setAttribute("class", "Tree-Research");
  }

  fetchBlockNo = () => {
    let { blockNumber } = this.state,
      url = "/blocksuuid";
    if (blockNumber) url += `?blockNo=${blockNumber}`;
    fetchApi(url, "get").then(data =>
      this.setState({ blockNoList: sortFunction(data, "blockNoFull") })
    );
  };

  fetchTallTreeResearch = (reload = true) => {
    if (reload) {
      this.setState({
        tableLoader: true,
        dynamicTableData: [],
        pagination: false
      });
      let { block_uuid, researchCode, researchDate } = this.state,
        url = "/tall_researches";
      if (block_uuid) url += `?block.uuid=${block_uuid}`;
      if (researchCode) url += `&research.code=${researchCode}`;
      if (researchDate) {
        if (block_uuid && researchDate) {
          url += `&researchDate%5Bbefore%5D=${moment(researchDate).format(
            "YYYY-MM-DD"
          )}`;
          url += `&researchDate%5Bafter%5D=${moment(researchDate).format(
            "YYYY-MM-DD"
          )}`;
        } else {
          url += `?researchDate%5Bbefore%5D=${moment(researchDate).format(
            "YYYY-MM-DD"
          )}`;
          url += `&researchDate%5Bafter%5D=${moment(researchDate).format(
            "YYYY-MM-DD"
          )}`;
        }
      }
      fetchApi(url, "get").then(data =>
        this.setState(
          {
            tableLoader: false,
            data: data.map(({ dateOfResearch, ...it }) => {
              return {
                dateOfResearch: formatDate(dateOfResearch),
                date: dateOfResearch,
                ...it
              };
            })
          },
          () => {
            this.setState(
              {
                dynamicTableData: sortFunction(this.state.data, "treeNo"),
                pagination: true
              },
              () =>
                this.setState(
                  {
                    dynamicTableData: sortFunction(
                      this.state.dynamicTableData,
                      "rowNo"
                    ),
                    pagination: true
                  },
                  () =>
                    this.setState(
                      {
                        dynamicTableData: sortFunction(
                          this.state.dynamicTableData,
                          "areaNo"
                        ),
                        pagination: true
                      },
                      () =>
                        this.setState({
                          dynamicTableData: sortFunction(
                            this.state.dynamicTableData,
                            "blockNo"
                          ),
                          pagination: true
                        })
                    )
                )
            );
          }
        )
      );
    }
  };

  fetchTallTree = () => {
    let { block_uuid } = this.state;
    //fetchApi("/talls?stageCode=3&block.uuid=" + block_uuid, "get").then(data =>
    fetchApi(
      "/talls?stageCode[]=2&stageCode[]=3&block.uuid=" + block_uuid,
      "get"
    ).then(data =>
      this.setState({ tallTree: sortFunction(data, "treeNoFull") })
    );
  };

  addModalToggle = () => {
    let {
      blockNumber,
      block_uuid,
      researchCode,
      researchDate,
      addModal,
      addPopulateData,
      isEdit,
      isDelete,
      researchCode_uuid,
      isAdded
    } = this.state;

    if (addModal) {
      this.setState(
        {
          addModal: false,
          addPopulateData: null,
          editData: null,

          blockNumber: isEdit || isDelete || isAdded ? blockNumber : null,
          block_uuid: isEdit || isDelete || isAdded ? block_uuid : null,
          researchCode: isEdit || isDelete ? researchCode : null,
          researchCode_uuid: isEdit || isDelete ? researchCode_uuid : null,
          researchDate: isEdit || isDelete ? researchDate : null,
          addPopulateData: isAdded
            ? {
              //blockNo: blockNumber,
              //research: researchCode,
              //researchDate: researchDate
            }
            : null,
          isEdit: false,
          isDelete: false,
          isAdded: false
        }
        //() => this.fetchTallTreeResearch()
      );
    } else {
      if (!blockNumber) {
        message.info("Select Block No.");
        // } else if (!researchCode) {
        //   message.info("Please Select a Research Code");
        // } else if (!researchDate) {
        //   message.info("Please Select a Research Date");
      } else {
        this.fetchTallTree();
        this.setState(
          {
            // addModal: blockNumber
            //   ? researchCode
            //     ? researchDate
            //       ? !this.state.addModal
            //       : false
            //     : false
            //   : false
            addModal: blockNumber ? !this.state.addModal : false
          },
          () => {
            // if (researchCode || researchDate) {
            this.setState({
              addPopulateData: Object.assign(
                {},
                blockNumber ? { blockNo: blockNumber } : {},
                researchCode ? { research: researchCode } : {},
                researchDate ? { researchDate: researchDate } : {},
                cloneDeep(this.state.addPopulateData)
              )
            });
            // }
          }
        );
      }
    }
  };

  clearFields = () => {
    this.setState({
      //spatheNo: null,
      //spatheNoList: null,
      researchDate: null,
      researchCode: null,
      addPopulateData: {
        //spatheNo: null,
        stage: null,
        treeType: null,
        area: null,
        row: null,
        details: null,
        tree: null,
        researchDate: null,
        research: null
      }
    });
  };

  addFormHandler = ({
    researchDate,
    research,
    block,
    area,
    row,
    treeType,
    tree,
    details,
    ...val
  }) => {
    //to remove the previous instances of
    notification.destroy();

    let {
      tallTreeSelectedData,
      researchCode_uuid,
      isEdit,
      tallResearchUuid,
      isDelete
    } = this.state;
    this.setState({ btnLoad: true });
    if (isEdit) {
      fetchApi(`/tall_researches/${tallResearchUuid}`, "put", {
        researchDate: researchDate.format("YYYY-MM-DD"),
        research: "/researches/" + researchCode_uuid,
        block: "/blocks/" + tallTreeSelectedData.blockUuid,
        area: "/areas/" + tallTreeSelectedData.areaUuid,
        row: "/rows/" + tallTreeSelectedData.rowUuid,
        treeType: "/tree_types/" + tallTreeSelectedData.treeTypeUuid,
        tree: "/talls/" + tallTreeSelectedData.uuid,
        details: details ? details : null,
        ...val
      }).then(res => {
        if (res) {
          notification.success({
            message: "Success",
            description: "Successfully updated Tall Tree Research"
          });
          this.fetchTallTreeResearch();
          this.addModalToggle()
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to updated Tall Tree Research"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    } else if (isDelete) {
      let today = new Date().toISOString().split("T")[0];
      fetchApi(`/tall_researches/${tallResearchUuid}`, "put", {
        dtDeleted: today
      }).then(res => {
        if (res) {
          notification.success({
            message: "Success",
            description: "Successfully deleted Tall Tree Research"
          });
          this.fetchTallTreeResearch();
          this.addModalToggle()
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to delete Tall Tree Research"
          });
        }
        this.setState({ btnLoad: false }
          //, () => this.addModalToggle()
        );
      });
    } else {
      fetchApi("/tall_researches", "post", {
        researchDate: researchDate.format("YYYY-MM-DD"),
        research: "/researches/" + researchCode_uuid,
        block: "/blocks/" + tallTreeSelectedData.blockUuid,
        area: "/areas/" + tallTreeSelectedData.areaUuid,
        row: "/rows/" + tallTreeSelectedData.rowUuid,
        treeType: "/tree_types/" + tallTreeSelectedData.treeTypeUuid,
        tree: "/talls/" + tallTreeSelectedData.uuid,
        details: details ? details : null,
        ...val
      }).then(res => {
        if (res) {
          if (res.violations) {
            notification.error({
              message: "Failed",
              description: res.violations[0].message
            });
          } else {
            notification.success({
              message: "Success",
              description: "Successfully added Tall Tree Research"
            });
            this.setState(
              {
                isAdded: true
              },
              () => this.clearFields()
            );

            this.fetchTallTreeResearch();
          }
        } else {
          notification.error({
            message: "Failed",
            description: "Failed to add Tall Tree Research"
          });
        }
        this.setState({ btnLoad: false, addPopulateData: null });
      });
    }
  };

  onBlockChangeHandler = it => {
    let { blockNoList } = this.state;

    if (it) {
      fetchApi(
        "/researches?type=2&properties[]=uuid&properties[]=code",
        "get"
      ).then(res => this.setState({ researchCodeList: res }));
    } else {
      this.setState({ researchCodeList: [] })
    }

    this.setState(
      {
        blockNumber: it ? it : null,
        researchCode: null,
        researchCode_uuid: null,
        block_uuid: it ? cloneDeep(blockNoList)?.filter(item => item.blockNoFull == it)?.[0]?.uuid : null,
        // researchCode: null,
        // researchCodeList: [],
        // researchDate: null,
        addPopulateData: {
          blockNo: it ? it : null
        }
      },
      () => this.fetchTallTreeResearch()
    );
  };

  onResearchCodeChangeHandler = (it, reload = true) => {
    let { researchCodeList } = this.state;
    this.setState(
      {
        researchCode: it ? it : null,
        researchCode_uuid: it
          ? cloneDeep(researchCodeList).filter(item => item.code == it)[0].uuid
          : null,
        addPopulateData: {
          research: it ? it : null
        }
        //researchDate: null
      },
      () => this.fetchTallTreeResearch(reload)
    );
  };

  onTreeNoChangeHandler = it => {
    let {
      block_uuid,
      tallTree,
      addPopulateData,
      isEdit,
      editData
    } = this.state;

    if (it) {
      this.setState({
        tallTreeSelectedData: cloneDeep(tallTree).filter(
          item => item.treeNoFull == it
        )[0]
      });

      this.setState(() => {
        let temp = cloneDeep(tallTree).filter(item => item.treeNoFull == it)[0];
        return {
          addPopulateData: isEdit
            ? {
              stage:
                temp.stageCode == 1
                  ? "Seedling"
                  : temp.stageCode == 2
                    ? "Plant"
                    : "Tree",
              treeType: temp.treeType,
              blockNo: temp.blockNo,
              area: temp.areaNo,
              row: temp.rowNo
            }
            : {
              stage:
                temp.stageCode == 1
                  ? "Seedling"
                  : temp.stageCode == 2
                    ? "Plant"
                    : "Tree",
              treeType: temp.treeType,
              blockNo: temp.blockNo,
              area: temp.areaNo,
              row: temp.rowNo,
              ...addPopulateData
            }
        };
      });
    }
  };

  tableFilterHandler = () => {
    let { blockNumber, researchCode, data } = this.state,
      filterJson = {};
    if (blockNumber) filterJson.blockNo = blockNumber;
    if (researchCode) filterJson.researchCode = researchCode.toString();
    this.setState({
      dynamicTableData: filter(data, filterJson)
    });
  };

  dateOfResearchHandler = (date, string) => {
    let { data, researchDate, researchCode } = this.state;

    if (date) {
      this.setState(
        {
          researchDate: date,
          addPopulateData: {
            researchDate: date
            //research: researchCode
          }
        },
        () => this.fetchTallTreeResearch()
        // ,
        // () =>
        // this.setState({
        //   dynamicTableData: cloneDeep(data).filter(
        //     it => it.dateOfResearch == string
        //   ),
        //   addPopulateData: {
        //     researchDate: date
        //   }
        // })
      );
    } else {
      this.setState(
        {
          //dynamicTableData: data,
          researchDate: null,
          addPopulateData: {
            researchDate: null
            //research: null
          }
        },
        () => this.fetchTallTreeResearch()
      );
    }
  };

  formdateOfResearchHandler = (date, string) => {
    let { data, researchDate, researchCode } = this.state;
    if (date) {
      this.setState({
        //researchDate: date,
        addPopulateData: {
          researchDate: date
          //research: researchCode
        }
      });
    } else {
      this.setState({
        //dynamicTableData: data,
        //researchDate: null,
        addPopulateData: {
          researchDate: null
          //research: null
        }
      });
    }
  };

  onActionClickHandler = (it, action) => {
    let { blockNoList, researchCodeList } = this.state;
    let blockuuid = cloneDeep(blockNoList)?.filter(
      item => item.blockNoFull == it.blockNo
    )[0]?.uuid;
    this.setState({ tableLoader: true });
    Promise.all([
      fetchApi(
        `/talls?stageCode[]=2&stageCode[]=3&block.uuid=${blockuuid}&treeNo=${it.treeNo}`,
        "get"
      ),
      // fetchApi(
      //   `/researches?type=2&properties[]=uuid&properties[]=code&code=${it.researchCode}`,
      //   "get"
      // ),
      fetchApi(
        `/tall_researches?tree.treeNo=${it.treeNo}&block.uuid=${blockuuid}&research.code=${it.researchCode}`,
        "get"
      )
    ]).then(data => {
      this.setState(
        {
          isEdit: action == "edit" ? true : false,
          isDelete: action == "delete" ? true : false,
          tallTree: data[0],
          //researchCodeList: data[1],
          tallResearchData: data[1],
          tableLoader: false
        },
        () => {
          this.setState({
            tallTreeSelectedData: this.state.tallTree[0],
            //researchCode_uuid: this.state.researchCodeList[0].uuid,
            researchCode_uuid: cloneDeep(researchCodeList)?.filter(item => item.code == it.researchCode)[0]?.uuid,
            tallResearchUuid: this.state.tallResearchData[0]?.uuid,
            addModal: true,
            editData: {
              //stage: "Tree",
              stage:
                this.state.tallTree[0]?.stageCode == 1
                  ? "Seedling"
                  : this.state.tallTree[0]?.stageCode == 2
                    ? "Plant"
                    : "Tree",
              tree: it.treeNo,
              treeType: it.treeType,
              researchDate: moment(it.date, "YYYY-MM-DD"),
              research: it.researchCode,
              blockNo: it.blockNo,
              area: it.areaNo,
              row: it.rowNo,
              details: this.state.tallResearchData[0]?.details
            }
          });
        }
      );
    });
  };

  render() {
    let {
      addModal,
      dynamicTableData,
      blockNoList,
      blockNumber,
      researchCodeList,
      researchCode,
      researchDate,
      tallTree,
      editData,
      isEdit,
      addPopulateData,
      tableLoader,
      btnLoad,
      pagination,
      isDelete,
      data
    } = this.state;

    const columns = [
      {
        title: "Tree No.",
        dataIndex: "treeNo"
      },
      {
        title: "Tree Type",
        dataIndex: "treeType"
      },
      {
        title: "Block No.",
        dataIndex: "blockNo"
      },
      {
        title: "Area No.",
        dataIndex: "areaNo"
      },
      {
        title: "Row No.",
        dataIndex: "rowNo"
      },
      {
        title: "Research Code",
        dataIndex: "researchCode"
      },
      {
        title: "Date of Research",
        dataIndex: "dateOfResearch"
      },
      {
        title: "Action",
        className: "action",
        render: it => (
          <Fragment>
            <Tooltip placement="topRight" title={"Edit"}>
              <Icon
                type="edit"
                className="table-action-btn"
                onClick={() => this.onActionClickHandler(it, "edit")}
              />
            </Tooltip>
            <Tooltip placement="topRight" title={"Delete"}>
              <Icon
                type="delete"
                className="table-action-btn"
                onClick={() => this.onActionClickHandler(it, "delete")}
              />
            </Tooltip>
          </Fragment>
        )
      }
    ];
    const buttonList =
      data.length > 0
        ? [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          },
          /*{
      label: "Excel",
      icon: "file-text",
      onClickHandler: () => console.log("Excel")
    },*/
          {
            label: "Print",
            icon: "diff",
            reference: this.componentRef
          },
          {
            label: this.state.pagination ? "View All" : "View Less",
            icon: "container",
            onClickHandler: () => this.setState({ pagination: !pagination })
          }
        ]
        : [
          {
            label: "Add",
            icon: "folder-add",
            onClickHandler: this.addModalToggle
          }
        ];
    const formItems =
      !isEdit && !isDelete
        ? [
          {
            name: "tree",
            placeholder: "Tree No.",
            listData: tallTree
              ? cloneDeep(tallTree).map(it => it.treeNoFull)
              : "",
            message: "Input the tree no.!",
            readonly: tallTree.length > 0 ? false : true,
            changeHandler: it => this.onTreeNoChangeHandler(it)
          },
          {
            name: "stage",
            placeholder: "Stage",
            disabled: true,
            message: "Input the stage!"
          },
          {
            name: "treeType",
            placeholder: "Tree Type",
            disabled: true,
            message: "Input the tree type!"
          },
          {
            name: "blockNo",
            placeholder: "Block No.",
            disabled: true,
            message: "Input the block no!"
          },
          {
            name: "area",
            placeholder: "Area No.",
            type: "number",
            disabled: true,
            message: "Input the area no!"
          },
          {
            name: "row",
            placeholder: "Row No.",
            type: "number",
            disabled: true,
            message: "Input the row no!"
          },
          {
            name: "research",
            placeholder: "Research Code",
            listData: cloneDeep(researchCodeList)?.map(it => it.code),
            disabled: false,
            message: "Input the research code!",
            changeHandler: it => this.onResearchCodeChangeHandler(it, false)
          },
          {
            name: "researchDate",
            placeholder: "Date of Research",
            type: "date",
            disabled: false,
            message: "Input the date of research!",
            changeHandler: this.formdateOfResearchHandler
          },
          {
            name: "details",
            placeholder: "Details",
            required: false,
            type: "alphanumeric"
          }
        ]
        : [
          {
            name: "tree",
            placeholder: "Tree No.",
            message: "Input the tree no.!",
            disabled: true,
            readonly: tallTree.length > 0 ? false : true
          },
          {
            name: "stage",
            placeholder: "Stage",
            disabled: true,
            message: "Input the stage!"
          },
          {
            name: "treeType",
            placeholder: "Tree Type",
            disabled: true,
            message: "Input the tree type!"
          },
          {
            name: "blockNo",
            placeholder: "Block No.",
            disabled: true,
            message: "Input the block no!"
          },
          {
            name: "area",
            placeholder: "Area No.",
            type: "number",
            disabled: true,
            message: "Input the area no!"
          },
          {
            name: "row",
            placeholder: "Row No.",
            type: "number",
            disabled: true,
            message: "Input the row no!"
          },
          {
            name: "research",
            placeholder: "Research Code",
            //listData: cloneDeep(researchCodeList).map(it => it.code),
            disabled: true,
            message: "Input the research code!",
            changeHandler: it => this.onResearchCodeChangeHandler(it, false)
          },
          {
            name: "researchDate",
            placeholder: "Date of Research",
            type: "date",
            disabled: isDelete ? true : false,
            message: "Input the date of research!",
            changeHandler: this.formdateOfResearchHandler,
            required: isDelete ? false : true
          },
          {
            name: "details",
            placeholder: "Details",
            required: false,
            type: "alphanumeric",
            disabled: isDelete ? true : false,
            required: false
          }
        ];
    return (
      <LocationPageBuilder
        title="Research Tall Tree"
        buttonList={buttonList}
        dropDownList={[
          {
            label: "Block",
            listItems: cloneDeep(blockNoList).map(it => it.blockNoFull),
            value: blockNumber ? blockNumber : undefined,
            changeHandler: it => this.onBlockChangeHandler(it)
          },
          {
            label: "Research Code",
            listItems: cloneDeep(researchCodeList)?.map(it => it.code),
            disabled: blockNumber ? false : true,
            value: researchCode ? researchCode : undefined,
            changeHandler: it => this.onResearchCodeChangeHandler(it)
          },
          {
            label: "Date of Research",
            type: "date",
            value: researchDate ? researchDate : undefined,
            changeHandler: this.dateOfResearchHandler
          }
        ]}
        addModal={addModal}
        addModalToggle={this.addModalToggle}
        addFormHandler={this.addFormHandler}
        btnLoad={btnLoad}
        editData={editData}
        addPopulateData={addPopulateData}
        isEdit={isEdit}
        isDelete={isDelete}
        formItems={formItems}
      // formItems={[
      //   {
      //     name: "tree",
      //     placeholder: "Tree No.",
      //     listData: tallTree
      //       ? cloneDeep(tallTree).map(it => it.treeNoFull)
      //       : "",
      //     message: "Input the tree no.!",
      //     disabled: isDelete || isEdit ? true : false,
      //     readonly: tallTree.length > 0 ? false : true,
      //     changeHandler: it => this.onTreeNoChangeHandler(it)
      //   },
      //   {
      //     name: "stage",
      //     placeholder: "Stage",
      //     disabled: true,
      //     message: "Input the stage!"
      //   },
      //   {
      //     name: "treeType",
      //     placeholder: "Tree Type",
      //     disabled: true,
      //     message: "Input the tree type!"
      //   },
      //   {
      //     name: "blockNo",
      //     placeholder: "Block No.",
      //     disabled: true,
      //     message: "Input the block no!"
      //   },
      //   {
      //     name: "area",
      //     placeholder: "Area No.",
      //     type: "number",
      //     disabled: true,
      //     message: "Input the area no!"
      //   },
      //   {
      //     name: "row",
      //     placeholder: "Row No.",
      //     type: "number",
      //     disabled: true,
      //     message: "Input the row no!"
      //   },
      //   {
      //     name: "research",
      //     placeholder: "Research Code",
      //     listData: cloneDeep(researchCodeList).map(it => it.code),
      //     disabled: false,
      //     message: "Input the research code!",
      //     changeHandler: it => this.onResearchCodeChangeHandler(it, false)
      //   },
      //   {
      //     name: "researchDate",
      //     placeholder: "Date of Research",
      //     type: "date",
      //     disabled: false,
      //     message: "Input the date of research!",
      //     changeHandler: this.dateOfResearchHandler
      //   },
      //   {
      //     name: "details",
      //     placeholder: "Details",
      //     required: false,
      //     type: "alphanumeric",
      //     disabled: isDelete ? true : false
      //   }
      // ]}
      >
        <div ref={this.componentRef}>
          {/* <h1>
            Research Tall Tree - List &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Farm
            : {getCookie(COOKIE.FARM)}{" "}
          </h1> */}
          <Spin spinning={tableLoader} size="large">
            <Table
              ref="table"
              columns={columns}
              dataSource={dynamicTableData}
              pagination={!pagination ? pagination : { pageSize: 15 }}
            //loading={tableLoader}
            locale={{
              emptyText: <Empty />
            }}
            />
          </Spin>
        </div>
      </LocationPageBuilder>
    );
  }
}

export default TallTree;
