import axios from "axios";
import { COOKIE, getCookie, deleteCookie } from "../cookie";

/**
 * @name fetchApi
 * @description will fet the data based on params supplied
 * @param {string} param
 * @param {string} method
 * @param {object} variables
 */
export const fetchApi = (param = null, method = null, variables = null) =>
  axios({
    method: method,
    // url: `${
    //   process.env.NODE_ENV == "development"
    //     ? //"http://api.localhost:8000"
    //       //"https://api.cdms.deejayfarms.com"
    //       "https://api.cdms.opspldev.in"
    //     //: "https://api.cdms.opspldev.in"          //Dev
    //     : "https://api.cdms.deejayfarms.com"    //Live
    //   //"http://api.cdms.ho.opspl.com:8090"
    //   }${param}`,
    // url:'https://api.cdms.opspl.in'+param,
    url:'https://api.cdms.deejayfarms.com'+param,
    data: variables,
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + getCookie(COOKIE.ID_TOKEN),
      code: getCookie(COOKIE.CODE),
      location: getCookie(COOKIE.FARM)
    }
  })
    .then(res => res.data)
    .catch(err => {
      let { status } = err.response;
      if (status === 401) {
        deleteCookie(COOKIE.ID_TOKEN);
        deleteCookie(COOKIE.NAME);
        deleteCookie(COOKIE.FARM);
        deleteCookie(COOKIE.CODE);
        deleteCookie(COOKIE.ROLE);
        deleteCookie(COOKIE.UUID);

        window.location.assign("/");
      } else if (status === 400 || status === 404) {
        if (err.response.data.violations) {
          return err.response.data;
        } else {
          return false;
        }
      }
    });

/**
 * @name fetchExelApi
 * @description will fet the data based on params supplied
 * @param {string} param
 * @param {string} method
 * @param {object} variables
 */
export const fetchExcelApi = (param = null, method = null, variables = null) =>
  axios({
    method: method,
    responseType: "blob",
    // url: `${
    //   process.env.NODE_ENV == "development"
    //     ? //"http://api.localhost:8000"
    //       //"https://api.cdms.deejayfarms.com"
    //       "https://api.cdms.opspldev.in"
    //     //: "https://api.cdms.opspldev.in"         //Dev
    //     : "https://api.cdms.deejayfarms.com"    //Live
    //   //"http://api.cdms.ho.opspl.com:8090"
    //   }${param}`,
    // url:'https://api.cdms.opspl.in'+param,
    url:'https://api.cdms.deejayfarms.com'+param,
    data: variables,
    headers: {
      Accept: "application/vnd.ms-excel, application/json",
      Authorization: "Bearer " + getCookie(COOKIE.ID_TOKEN),
      code: getCookie(COOKIE.CODE),
      location: getCookie(COOKIE.FARM)
    }
  })
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", res.headers.filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => {
      console.log(err);

      let { status } = err.response;
      if (status === 401) {
        deleteCookie(COOKIE.ID_TOKEN);
        deleteCookie(COOKIE.NAME);
        deleteCookie(COOKIE.FARM);
        deleteCookie(COOKIE.CODE);
        window.location.assign("/");
      } else {
        return false;
      }
    });
